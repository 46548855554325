/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    /**
     * Universal click or tap event
     * (replacement for 'click')
     */

    var clickEvent = function () {
        return ('undefined' !== typeof window.touchstart ? 'touchstart' : 'click');
    };

    var winWidth = $(window).width(),
        xs       = false,
        sm       = false,
        md       = false;

    /**
    * Mobile
    * Window width
    */

    function mobileCheck() {
        if ( winWidth >= '768' ) {
            md = true;
            sm = false;
            xs = false;
        } else if ( winWidth >= '576' ) {
            md = false;
            sm = true;
            xs = false;
        } else {
            md = false;
            sm = false;
            xs = true;
        }
    }

    mobileCheck();

    $(window).resize(function() {
        winWidth = $(window).width();
        mobileCheck();
    });

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Delta = {

        // All pages
        'common': {

            init: function() {
                // JavaScript to be fired on all pages

                /**
                 * Site header
                 */

                // var siteHeaderStyle = function() {
                //     if ( 10 <= $(document).scrollTop() ) {
                //         $('#site-header').addClass('is-not-top');
                //     } else {
                //         $('#site-header').removeClass('is-not-top');
                //     }
                // };

                // siteHeaderStyle();
                //
                // $(window).on('scroll', function() {
                //     siteHeaderStyle();
                // });


                /**
                 * Site nav
                 */

                var closePrimaryNavSubMenus = function($this) {
                    if ( ! $this ) {
                        $('#menu-primary .sub-menu-active').removeClass('sub-menu-active');
                        $('#menu-primary .sub-menu').stop().velocity('slideUp');
                    } else {
                        $('#menu-primary .sub-menu-active').not($this).removeClass('sub-menu-active');
                        $('#menu-primary .sub-menu').not($this.children('.sub-menu'))
                            .stop().velocity('slideUp');
                    }
                },

                openPrimaryNavSubMenus = function($this) {
                    closePrimaryNavSubMenus($this);

                    $this.addClass('sub-menu-active');

                    $this.children('.sub-menu')
                        .stop().velocity('slideDown');
                },

                closePrimaryNav = function() {
                    closePrimaryNavSubMenus();
                    $('body').removeClass('mobile-nav-is-active');
                },

                openPrimaryNav = function() {
                    $('body').addClass('mobile-nav-is-active');
                };

                /* Not mobile */

                if ( md ) {

                    $(document).on({
                        mouseenter: function(e) {
                            $(this).children('.sub-menu').stop()
                                .velocity('slideDown', { duration: 250 });
                            e.stopPropagation();
                        },
                        mouseleave: function(e) {
                            $(this).children('.sub-menu').stop()
                                .velocity('slideUp', { duration: 150 });
                            e.stopPropagation();
                        }
                    }, '#menu-primary > li.menu-item');

                }

                /* Mobile */

                // Open

                $(document).on(clickEvent(), '#site-nav .mobile-nav-trigger', function(e) {
                    e.preventDefault();
                    if ( ! $('body').hasClass('mobile-nav-is-active') ) {
                        openPrimaryNav();
                    }
                });

                // Close

                $(document).on(clickEvent(), 'body.mobile-nav-is-active', function(e) {
                    if ( $(e.target) === $('.mobile-close') ||
                        $(e.target).parents('.mobile-close').length ) {
                        closePrimaryNav();
                    }
                });

                // Submenu expand

                if ( ! md ) {

                    $(document).on(clickEvent(), '.menu-item-has-children .expand', function(e) {
                        e.preventDefault();
                        if ( ! $(this).parent().hasClass('sub-menu-active') ) {
                            closePrimaryNavSubMenus($(this).parent());
                            openPrimaryNavSubMenus($(this).parent());
                        } else {
                            closePrimaryNavSubMenus();
                        }
                    });

                }


                /**
                 * Site search
                 */

                var closeSearch = function() {
                    $('body').removeClass('search-is-active');
                    $('#site-search input.search-field').blur();
                    $('#menu-primary .menu-item.search').find('i')
                        .removeClass('la-close')
                        .addClass('la-search');
                },

                openSearch = function() {
                    $('body').addClass('search-is-active');
                    $('#site-search input.search-field').focus();
                    $('#menu-primary .menu-item.search').find('i')
                        .removeClass('la-search')
                        .addClass('la-close');
                },

                closeMobileSearch = function() {
                    $('body').removeClass('mobile-search-is-active');
                    $('#site-nav .mobile-search-trigger').children('i')
                        .removeClass('la-close')
                        .addClass('la-search');
                },

                openMobileSearch = function() {
                    $('body').addClass('mobile-search-is-active');
                    $('#site-nav .mobile-search-trigger').children('i')
                        .removeClass('la-search')
                        .addClass('la-close');
                };

                /* Not mobile */

                $(document).on(clickEvent(), '#menu-primary .menu-item.search a', function(e) {
                    e.preventDefault();
                    openSearch();
                });

                $(document).on(clickEvent(), 'body.search-is-active', function(e) {
                    if ( ! $(e.target).parents('#site-search').length ||
                        $(e.target).parents('#site-search .close').length
                    ) {
                        closeSearch();
                    }
                });

                /* Mobile */

                // Open

                $(document).on(clickEvent(), '#site-nav .mobile-search-trigger', function(e) {
                    e.preventDefault();
                    if ( ! $('body').hasClass('mobile-search-is-active') ) {
                        openMobileSearch();
                    }
                });

                // Close on body click

                $(document).on(clickEvent(), 'body.mobile-search-is-active', function(e) {
                    if ( ! $(e.target).parents('#site-search').length ||
                       $(e.target).parents('#site-search .close').length
                    ) {
                        closeMobileSearch();
                    }
                });

                /* Window resize */

                $(window).resize(function() {

                    if ( md ) {
                        $('body')
                            .removeClass('mobile-nav-is-active')
                            .removeClass('mobile-search-is-active');
                        closePrimaryNavSubMenus();
                    }

                });

            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }

        }, // common

        'home': {

            init: function() {

                $('.hero-carousel .owl-carousel').owlCarousel({
                    items:     1,
                    autoplay:  true,
                    loop:      true,
                    mouseDrag: true,
                    dots:      false,
                    nav:       true,
                    navText:   [
                        '<i class="la la-angle-left"></i><span class="sr-only">Previous</span>',
                        '<span class="sr-only">Next</span><i class="la la-angle-right"></i>'
                    ]
                });

            },
            finalize: function() {
            }

        },

        'page': {

            init: function() {


                /* Scroll to section header and open/close target content */

                var scrollToHeaderAndOpenContent = function(target, offset) {

                    var $headers       = $('.accordion-header'),
                        $contents      = $('.accordion-content'),
                        $targetHeader  = $(target),
                        $targetBtn     = $targetHeader.find('.btn-content-trigger'),
                        $targetContent = $($targetHeader.data('target-content'));

                    if ( null == offset ) {
                        offset = '10';
                    }

                    if ( $('#quick-find-modal').length ) {
                        $('#quick-find-modal').modal('hide');
                    }

                    // Close all other header & content areas
                    //
                    // @todo: This messes with the scrolling action

                    // $headers.each(function(i) {
                    //     $(this).not($targetHeader).removeClass('active');
                    //     $('.btn-content-trigger').text('Show All');
                    // });
                    //
                    // $contents.each(function(i) {
                    //     $(this).not($targetContent).velocity('slideUp');
                    // });

                    // Open/close target content

                    if ( $targetHeader.hasClass('active') ) {
                        $targetHeader.removeClass('active');
                        $targetContent.velocity('slideUp');
                        $targetBtn.text('Show All');

                    } else {
                        $targetHeader.addClass('active');
                        $targetContent.velocity('slideDown');
                        $targetBtn.text('Hide');
                    }

                    // Scroll to title

                    $targetHeader.velocity('scroll', { offset: offset });
                };

                var urlHashScrollAndOpen = function(e) {

                    var hash = window.location.hash;

                    if ( hash ) {
                        e.preventDefault();
                        e.stopPropagation();

                        setTimeout(function() {
                            scrollToHeaderAndOpenContent( hash );
                        }, 1);
                    }
                };

                $('.accordion-content').hide();

                /* Intercept URL hash on page load, smooth scroll to section, and open associated section */

                $(window).load(function(e) {
                    urlHashScrollAndOpen(e);
                });

                $(window).on('hashchange', function(e) {
                    $('body').removeClass('mobile-nav-is-active');
                    urlHashScrollAndOpen(e);
                });

                if ( $('.accordion-content').length ) {

                    var selectors = '.btn-content-trigger, #quick-find-modal .cat-link';

                    $(document).on('click', selectors, function(e) {
                        e.preventDefault();
                        scrollToHeaderAndOpenContent( $(this).data('target-header') );
                    });

                }

                if ( $('#quick-find-modal').length ) {

                    var $filterCheckbox = $('#quick-find-modal [type="checkbox"]'),
                        $filterSubmit   = $('#quick-find-modal [type="submit"]'),
                        isChecked       = false;

                    $(document).on('change', $filterCheckbox, function(e) {
                        $filterCheckbox.each(function(i) {
                            if ( $(this).is(':checked') ) {
                                isChecked = true;
                                return false;
                            } else {
                                isChecked = false;
                            }
                        });

                        if ( isChecked ) {
                            $filterSubmit.prop('disabled', false);
                        } else {
                            $filterSubmit.prop('disabled', true);
                        }
                    });
                }

                /* Ajax for post filtering by 'badges' */

                $(document).on('click', '.btn-post-filter', function(e) {
                    e.preventDefault();

                    $('.btn-post-filter').not($(this)).removeClass('active');
                    $(this).addClass('active');

                    var badge      = $(this).data('filter'),
                        postType   = $(this).data('posttype'),
                        catSlug    = $(this).data('catslug'),
                        $content   = $('#' + catSlug + '--posts'),
                        $container = $content.parents('.accordion-content');
                        $loader    = $('.loading-container');

                    $container.addClass('loading');
                    $content.velocity('fadeOut', { duration: 250 });
                    $loader.velocity('fadeIn', { duration: 500 });

                    $.get(
                        '/wp-admin/admin-ajax.php',
                        {
                            action:    'posts_filter',
                            post_type: postType,
                            cat_slug:  catSlug,
                            badge:     badge
                        },
                        function(data) {
                            $loader.velocity('fadeOut', {
                                duration: 250,
                                complete: function() {
                                    $content.html(data).velocity('fadeIn', {
                                        duration: 500,
                                        complete: function() {
                                            $container.removeClass('loading').addClass('loaded');
                                        }
                                    });
                                }
                            });
                        },
                        'html'
                    );
                });

            },
            finalize: function() {
            }

        },

        'single': {

            init: function() {

                // Title move

                var moveTitle = function() {
                    if ( ! md ) {
                        $('.single-header').prependTo('.col-single-imagery');
                    } else {
                        $('.single-header').prependTo('.single-content');
                    }
                };

                if ( $('.single-header').length ) {

                    moveTitle();

                    $(window).resize(function() {
                        moveTitle();
                    });

                }

                /* Share popover */

                if ( $('.single-share').length ) {

                    $(document).on(clickEvent(), '.share-trigger', function(e) {
                        e.preventDefault();
                        if ( ! $('#share-content').hasClass('is-active') ) {
                            $('#share-content').addClass('is-active');
                        } else {
                            $('#share-content').removeClass('is-active');
                        }
                    });

                    $(document).on(clickEvent(), 'body', function(e) {
                        if ( ! $(e.target).parents('#share-content').length &&
                            ! $(e.target).parents('.share-trigger').length ) {
                            if ( $('#share-content').hasClass('is-active') ) {
                                $('#share-content').removeClass('is-active');
                            }
                        }
                    });

                }

                /* Carousels */

                // Related products/recipes carousels

                $('.related-carousel').owlCarousel({
                    items:     3,
                    autoplay:  false,
                    loop:      true,
                    mouseDrag: false,
                    dots:      false,
                    nav:       true,
                    navText:   [
                        '<i class="la la-angle-left"></i><span class="sr-only">Previous</span>',
                        '<span class="sr-only">Next</span><i class="la la-angle-right"></i>'
                    ],
                    responsive: {
                        0: {
                            items: 1
                        },
                        576: {
                            items: 2
                        },
                        768: {
                            items: 3
                        }
                    }
                });

            }

        }


    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Delta;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
